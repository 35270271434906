    // Example starter JavaScript for disabling form submissions if there are invalid fields
    (function () {
        'use strict'

        var myCollapsible = document.getElementById('searchCollapse');
        if(myCollapsible) {
            myCollapsible.addEventListener('shown.bs.collapse', function () {
                document.getElementById('searchParam').focus();
            })
        }

        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                }, false)
            })
    })()
